import HomePage from "./home";
import BackToTop from "./backToTop";
import CPStaticContentHome from '../models/CPStaticContentHome.js'

export default function Home(data) {
  // console.log("home data", data)

  return (
    <>
      <main className="">
        <HomePage data={data} />
        <BackToTop />
      </main>
    </>
  );
}


export async function getStaticProps(context) {
  const homeContent = new CPStaticContentHome()
  await homeContent.fetchContent()
  return await homeContent.getListProps()
} 